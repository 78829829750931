.gold-bg {
  background-color: #ffd700;
}

.text-gold {
  color: #ffd700;
}

.bg-test {
  background-color: #15435b;
}

.box {
  position: relative;
  display: inline-block;
}

.box .centered {
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 999;
  top: 10%;
  width: 60%;
}
